<template lang="pug">
  .quick-start-page
    .content
      .header
        h2.nio-h6.text-primary-dark QUICK START GUIDE
        h1.nio-jumbo-2.text-primary-darker 
          span.row Welcome to 
          span.row Universal Onboarding
        p.nio-p-large.text-primary-dark Upload your offline customer data and find their digital matches. 
      .graphic
        img(src="https://cdn.narrative.io/images/data-stream/images/quick-start-headline.svg")  
      .actions
        .get-started
          p.nio-p.text-primary-dark Get started and we’ll guide you through every step.
          NioButton(
            @click="newOnboarding"
            jumbo-primary 
          ) Start Onboarding
        NioDivider(horizontal-or)
        .steps 
          .message.nio-p.text-primary-dark To ensure your first onboarding runs smoothly, consider the following:
          NioSlatGroup.steps
            NioIconLinkSlat(
              v-if="!paymentMethod"
              icon-name="display-payment"
              @click.native="openPayment"
            )
              template(v-slot:title) Add a mode of payment
              template(v-slot:subtitle) Save your credit card information for faster checkout.
            NioIconLinkSlat(
              icon-name="display-list" 
              display-only
            )
              template(v-slot:title) Get your lists ready
              template(v-slot:subtitle) Login to your sources to import lists to the List Manager.
              template(v-slot:action)
                NioPill(:text="'coming'")
            NioIconLinkSlat(
              icon-name="display-destinations" 
              display-only
            )
              template(v-slot:title) Set up your destinations
              template(v-slot:subtitle) Connect your external accounts for fast data delivery.
              template(v-slot:action)
                NioPill(:text="'coming'")
        NioSlatGroup.start
          NioIconLinkSlat(
            icon-name="display-kb" 
            @click.native="openKb"
          )
            template(v-slot:title) Deep dive into data onboarding
            template(v-slot:subtitle) Learn about the process and ways to troubleshoot.
</template>

<script>

export default {
  computed: {
    paymentMethod() { return this.nioPaymentMethod }
  },
  methods: {
    newOnboarding() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    openPayment() {
      window.open('https://app.narrative.io/settings/payment')
    },
    openKb() {
      window.open('https://kb.narrative.io/universal-onboarding')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.quick-start-page
  display: flex
  flex-direction: column
  align-items: center
  padding: 24px
  .content
    width: 640px
    display: flex
    flex-direction: column
    align-items: center
    .header
      display: flex
      flex-direction: column
      align-items: center
      text-align: center
      margin-bottom: 56px
      h1, h2
        margin-bottom: 16px
      h1
        width: 450px
        .row
          display: block
      p
        width: 670px
    .graphic
      width: 575px
      height: 250px    
      margin-bottom: 56px
    .actions
      width: 100%
      .get-started
        display: flex
        flex-direction: column
        align-items: center
        width: 100%
        p, .nio-button
          margin-bottom: 16px 
      .divider
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 16px
        .left, .right
          flex-grow: 2
          height: 0px
          border-bottom: 1px solid $c-primary-dark
        .text
          margin-left: 10px
          margin-right: 10px
      .steps
        margin-bottom: 24px
        .message
          text-align: center
          margin-bottom: 16px
      .start    
</style>